import React from 'react'
import { graphql } from 'gatsby'
import { Container, Flex } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import ContentButtons from '@solid-ui-components/ContentButtons'
import Faq from '@solid-ui-blocks/Faq/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const FAQPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Frequently Asked Questions' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='narrow'>
        <Faq content={content['faq']} />
      </Container>
      <Divider space='5' />
      <Container>
        <Flex sx={{ justifyContent: 'center' }}>
          <ContentButtons content={content['call-to-action']['buttons']} />
        </Flex>
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query faqPageBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/faq", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default FAQPage
